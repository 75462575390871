@media screen and (max-width: 500px) {
    body {
        margin: 0;
    }

    #page-wrap {
        padding: 0 20px !important;
    }
}

body {
    font-family: 'Open Sans Condensed';
}

  
#page-wrap {
    padding: 0 40px;
}

a {
    text-decoration: none;
    color: #000;
}

.spinnerDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    width: 100px;
    margin: auto;
    padding: 10px;
    color: #8491A3;
}